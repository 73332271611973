<template>
  <div class="flex gap-2 items-center">
    <b-form-input
      :value="value"
      @input="input"
      size="sm"
      autocomplete="off"
    ></b-form-input>
    <plus-button
      :value="value"
      :max="max"
      @input="input"
    />
    <minus-button
      :value="value"
      :min="min"
      @input="input"
    />
  </div>
</template>

<script>
import { clampNumber } from '@/helpers';

const PlusButton = () => import('@/components/labels/PlusButton.vue');
const MinusButton = () => import('@/components/labels/MinusButton.vue');

export default {
  name: 'NumberField',
  components: {
    PlusButton,
    MinusButton,
  },
  props: {
    value: [Number, String],
    min: [Number, String],
    max: [Number, String],
  },
  data() {
    return {};
  },
  methods: {
    input(event) {
      const min = this.min === undefined ? Number.NEGATIVE_INFINITY : parseInt(this.min, 10);
      const max = this.max === undefined ? Number.POSITIVE_INFINITY : parseInt(this.max, 10);
      const value = parseInt(event, 10);
      const clampedValue = clampNumber(value, min, max);
      this.$emit('input', isNaN(clampedValue) ? event : clampedValue);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
